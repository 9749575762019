<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="recurrence" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-recurrence-view-information :recurrence="recurrence" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getRecurrenceById } from '@/modules/recurrence/recurrence.service';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaRecurrenceViewInformation from '@/modules/recurrence/components/RecurrenceViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaRecurrenceViewPage',
  components: {
    GetecmaRecurrenceViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Recorrências', path: MY_SPACE_URL.recurrences.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      recurrence: null,
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    recurrence_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getRecurrenceById(this.recurrence_id)
        .then(data => {
          this.recurrence = data;
        })
        .catch(() => toastError('Erro ao obter recorrência por ID'));
    },
  },
};
</script>
