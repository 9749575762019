<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Recorrência:</b> {{ recurrence.number }} {{ frequency }}
      </getecma-p>
    </div>
  </div>
</template>

<script>
import { FREQUENCIES } from '@/modules/recurrence/recurrence.constants';

export default {
  name: 'GetecmaRecurrenceViewInformation',
  props: {
    recurrence: {
      type: Object,
      required: true,
    },
  },
  computed: {
    frequency() {
      return Object.values(FREQUENCIES)[this.recurrence.frequency - 1].value;
    },
  },
};
</script>
